/* ---- Coloring ---- */
:root {
  --primary: #2346A0;
  --secondary: #7ab340;
}
html {
    scrollbar-color: var(--primary) #e0e0e0;
    scrollbar-width: thin;
}
html, body { 
    height:100%;    
}
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo, .logo-pl {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes bounce-once {
    0%   { transform: translate(-50%, 0); }
    50%  { transform: translate(-50%, 12px); }
    100% { transform: translate(-50%, 0); }
}

/* ---- Custom CSS ---- */
.App-footer {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
}
.logo-sm { width: 15vmin; }
.logo-lg { width: 15vmin; }
.logo-bar { width: 20vw; }
.logo-intro { width: 18vw; }
.icon:hover {
    color: var(--primary) !important;
}
.novo { color: var(--primary) !important; }
.energy { color: var(--secondary) !important; }
.form-error {
    font-size: 0.8rem;
    color: var(--primary);
    text-align: center;
    margin: 0 !important;
    padding: 0px;
}
.flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
}
.menu-link {
    text-decoration: none;
    color: var(--primary);
    width:100%;
}
.menu-link:hover {
    color:#ffffff;
}
.txt-n-error {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0.2rem 0px;
}
.separator {
    width: 2vmin;
}
.bolder {
    font-weight: 700;
    color: var(--primary);
}
.parallax-title { font-size: 3.5rem }
.tab-panel { width:100%; height:100%; }
.ref-container { width:100%; height:100%; }
.css-agdkjh-MuiTabs-scrollbarSize {
    width: 50px !important;
}
/* .css-tx3tai-MuiButtonBase-root-MuiTab-root {
    color: rgba(240, 240, 240, 0.7) !important;
    color: var(--secondary) !important;
} */
.css-tx3tai-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: var(--secondary) !important;
}
.react-swipeable-view-container,
.react-swipeable-view-container > div {
    height:100%;
}
.p-li { padding: 5px }
.p-ol { margin: 5px 0px }
.mob-section {
    height:100vh;
    width:100%;
    scroll-snap-align: start;
    scroll-snap-stop: always;
}
/* ---- media Queries ---- */
@media only screen and (max-width: 600px) {
    .parallax-container {
        background-color: var(--primary);
        background-attachment: scroll;
    }
    .parallax-title {
        font-size: 1.8rem
    }
    .flex-row {
        flex-direction: column;
    }
    .txt-n-error {
        margin: 0px 0px;
    }
    .map-container {
        width: 85% !important;
        height: 200px !important;
    }
    .logo-sm { width: 25vw; }
    .logo-lg { width: 15vmin; }
    .logo-bar { width: 35vw; }
    .logo-intro { width: 50vw; }
}
@media only screen and (min-width: 600px) {
    .logo-bar { width: 210px; }
    .map-container {
        width: 70% !important;
        height: 220px !important;
    }
}
@media only screen and (min-width: 900px) {
    .logo-bar { width: 20vw; }
    .map-container {
        width: 70% !important;
        height: 300px !important;
    }
}
@media only screen and (min-width: 1200px) {
    .logo-bar { width: 240px; }
    .map-container {
        width: 60% !important;
        height: 350px !important;
    }
}
@media only screen and (min-width: 1536px) {
    .logo-bar { width: 280px; }
}
